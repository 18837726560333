import { Image, Link } from '@chakra-ui/react';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';

export default function NbiBanner({ gaEventLabel }: { gaEventLabel: string }) {
  return (
    <Link
      href="https://www.boehringer-ingelheim.com/jp"
      isExternal
      onClick={() => {
        sendAnalyticsEvent('slide', 'bi_company_banner', gaEventLabel);
      }}
      height="auto"
    >
      <Image
        alt="Boehringer Ingelheim"
        src={'/images/banner_company_bi_800*600.jpg'}
        width={395}
        height="auto"
        borderRadius={8}
      />
    </Link>
  );
}
